.navbar {
    padding: 35px 73px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.navbar img {
    width: 132px;
    height: 60px;
    cursor: pointer;
}

.navbar .nav-item {
    display: flex;
    flex-direction: row;
    gap: 30px;
    list-style: none;
    align-items: center;
}

.category-modal {
    display: none !important;
}

.navbar .nav-item .nav-link {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center !important;
    cursor: pointer;
    color: #243459;
    transition: color 0.3s;
}

/* .nav-item .nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 50%;
    background-color: #243459;
    transition: width 0.3s ease, left 0.3s ease;
}

.nav-item .nav-link:hover::after {
    width: 100%;
    left: 0;
} */

.nav-item .nav-link:hover {
    color: var(--primary-color);
}

.nav-item .nav-link.active {
    background-color: var(--primary-color);
    border-radius: 5px;
    padding: .5rem;
    color: white;
}

/* Show dropdown on hover */
.navbar .nav-item .nav-link:hover .dropdown-content {
    display: block;
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scale-up-center {
    0% {
        transform: scale(.5)
    }

    100% {
        transform: scale(1)
    }
}

/* Dropdown container */
.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: -500%;
    min-width: 100%;
    height: fit-content;
    background-color: #F8F0F8;
    box-shadow: var(--shadow);
    border-radius: 4px;
    animation: scale-up-center .4s cubic-bezier(.39, .575, .565, 1.000) both;
    color: #243459;
    overflow: hidden;
    z-index: 10;
}


.dropdown-content ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 0px;
}

.dropdown-content ul .row- {
    display: flex;
    flex-direction: row;
}

.dropdown-content ul .col {
    /* border-right: 1px solid #D4CAD9; */
    margin: .5rem;
}

.dropdown-content ul .col:last-child {
    border-right: none;
    margin-right: .5rem;
}

.dropdown-content ul span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.dropdown-content ul span img {
    width: 42px;
    height: 42px;
}

.dropdown-content ul li {
    padding: 20px 5px;
    margin: .5rem 0rem;
    cursor: pointer;
    width: 200px;
    transition: 0.3s ease-in-out;
    font-size: 15px;
    max-width: 350px;
    height: 50px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center !important;
    cursor: pointer;
    color: #243459;
    border-radius: .5rem;
    border-right: 2px solid #e3c8f1;
}

.dropdown-content ul li:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
}

.nav-acc {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    gap: 10px;
}


.nav-acc .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #243459;
}

.nav-acc .profile span {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.my-plan {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 140px;
    min-height: 40px;
    border: 1px solid #243459;
    border-radius: 20px;
    gap: 5px;
}

.my-plan .my-plan-credit img {
    width: 20px;
    height: 20px;
}

.my-plan-credit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-left: 5px;
    color: #243459;
    font-weight: 600;
    font-size: 12px;
}

.my-plan .my-plan-btn {
    background: linear-gradient(90deg, hsla(242, 76%, 67%, 1) 0%, hsla(285, 100%, 85%, 1) 100%);
    padding: 10px;
    border-radius: inherit;
    transition: background 0.3s;
    margin-right: 2px;
}

.my-plan .my-plan-btn:hover {
    background: #243459;
}

.my-plan .my-plan-btn button {
    background-color: transparent;
    color: white;
}

/* Define keyframes for the rotation animations */
.icon-down {
    transition: transform 0.3s ease;
}

.icon-down.rotate {
    transform: rotate(180deg);
}

.profile img,
.nav-profile-dropdown-header img {
    width: 30px;
    height: 30px;
}

.profile p {
    font-size: 16px;
    font-weight: 500;
}

.nav-mob-item {
    display: none;
}

.nav-profile-dropdown {
    position: absolute;
    top: 50%;
    left: -50%;
    min-width: fit-content;
    height: fit-content;
    background-color: #F8F0F8;
    box-shadow: var(--shadow);
    padding: 5px;
    color: #243459;
    border-radius: 10px;
    overflow: hidden;
    animation: scale-up-center .4s cubic-bezier(.39, .575, .565, 1.000) both;
    z-index: 10;
    min-width: 200px;
    max-width: 350px;
}

.nav-profile-dropdown-header {
    display: flex;
    gap: 10px;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
}

.nav-profile-dropdown-header span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    text-align: left;
}

.nav-profile-dropdown-header p {
    font-size: 19px;
}

.nav-profile-dropdown-header h3 {
    display: flex;
    font-size: 12px;
}

.nav-profile-dropdown-header .profile-type {
    background-color: var(--primary-color);
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
}

.nav-profile-dropdown-header .profile-type:hover {
    background-color: #243459;
}

.divider {
    flex-grow: 1;
    height: 1px;
    background-color: rgba(152, 152, 153, 0.5);
    margin: 2px 0px;
}

.nav-profile-dropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.nav-profile-dropdown li {
    padding: 10px;
    cursor: pointer;
    min-width: 150px;
    text-align: left;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
}

.nav-profile-dropdown li:hover {
    background-color: #e6e5f9;
    color: var(--primary-color);
}

.hamburger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger-menu div {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
}

.navbar-menu {
    position: absolute;
    min-width: 300px;
    right: 2%;
    top: 10%;
    background-color: #F8F0F8;
    color: #243459;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: slide-down 0.3s ease-in-out;
    border-radius: 4px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 10000;
}

.nav-menu-item li {
    display: flex;
    list-style: none;
    padding: 10px 20px;
    align-items: center;
}

.nav-menu-item li:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.nav-menu-item {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 20px;
}


/* for hero section */
.Hero-section {
    background-image: url("../assets/hero-bg.png");
    height: 483px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-heading {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.heading-content {
    text-align: center;
}

.video-modal {
    width: 50%;
    height: fit-content;
    background-color: #f7f7f7;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--shadow);
}

.video-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #D9D9D9;
    color: #1e1e1e;
    padding: 1rem;
}

.video-body {
    position: relative;
    height: 500px;
    padding: 1rem;

}

.demo-video {
    position: absolute;
    right: 75px;
    top: 40%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.demo-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
}

.demo-btn button {
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: capitalize;
}

.demo-btn button:hover {
    background-color: var(--primary-color);
    color: white;
}

/* .demo-btn button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(178, 105, 214, 0.5);
} */

.demo-video img {
    margin-right: 2rem;
    align-self: flex-start;
    animation: blinkFade 3s infinite;
}

/* Keyframes for the blink and fade-in/out effect */
@keyframes blinkFade {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    70% {
        opacity: 0.5;
    }

    90% {
        opacity: 1;
    }
}

.hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.hero-content h1,
.jobDetail-navbar h1 {
    font-size: 64px;
    color: #243459;
    line-height: 96%;
    font-weight: bold;
    margin-bottom: 33px;
}

.hero-content p {
    color: #989899;
    font-size: 18px;
}

.hero-search form {
    display: inline-flex;
    margin: 40px auto;
    /* box-shadow: 2px 2px 22px rgba(0, 0, 0, 0.12); */
    align-items: center;
    gap: 15px;
}

.hero-search form input {
    width: 420px;
    height: 51px;
    padding: 18px;
    font-size: 18px;
    color: rgba(20, 20, 20, 0.5);
    box-shadow: var(--shadow);
    border-radius: .8rem;

}

/* .clear-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    height: 51px;
    color: rgba(20, 20, 20, 0.5);
    width: 30px;
    transition: 0.3s ease-in-out;
} */

.hero-search form button {
    width: fit-content;
    height: 50px;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: 16px;
    font-weight: 500;
    border-radius: .8rem;
    box-shadow: var(--shadow);
    transition: background-color 0.3s;
    padding: 1rem;
}

.search-icon {
    display: none;
}

.hero-search form button:hover {
    background-color: #243459;
}

/* for progress bar */
/* ProgressBar.css */

.progress-bar-container {
    width: 100%;
    height: 5px;
    /* Adjust height as needed */
    background-color: #D9D9D9;
    border-radius: 5px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #EBB1FF;
    transition: width 0.3s ease-in-out;
}

.job-box {
    border: 1px solid rgba(20, 20, 20, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 24px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    border-radius: 8px;
    cursor: pointer;
    /* max-width: 1024px; */
    min-width: 100%;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.job-box:hover {
    transform: translateY(-10px);
    background-color: #F9F9F9;
    box-shadow: 0 5px 10px rgba(77, 103, 231, 0.5);
}

.job-box .job-building-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.job-box .job-building-icon img {
    width: 50px;
    height: auto;
    object-fit: contain;
}

.job-box h3 {
    margin-bottom: 6px;
    font-size: 24px;
    font-weight: 500;
}

.job-box .cal-icon {
    display: flex !important;
    margin-bottom: 12px;
    color: rgba(20, 20, 20, 0.7);
    align-items: center;
    gap: 5px;
}

.tags-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin: 10px 0px;

}


.tags-list .tag {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 20px;
    gap: 10px;
}

.tags-list .tag span {
    margin: 0px;
}

/* footer */
.footer {
    display: flex;
    height: 60px;
    padding: 20px 40px;
    background-color: #243459;
    font-size: 14px;
    font-weight: 500;
    color: white;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* for stripe form */

.stripe-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
}

.card-number,
.cvc,
.card-name,
.expiry {
    flex: 1;
    border-radius: 8px;
}

.credit-card-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.credit-card-form input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.stripe-row input:focus {
    border-color: var(--primary-color);
    outline: none;
}

.stripe-row input::placeholder {
    color: #aaa;
}

.card-number .StripeElement,
.cvc .StripeElement,
.expiry .StripeElement {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
}

.card-number .StripeElement:focus,
.cvc .StripeElement:focus,
.expiry .StripeElement:focus {
    border-color: var(--primary-color);
}

.invalid {
    color: #fa755a;
}


@media screen and (max-width: 1030px) {

    .hero-content h1,
    .jobDetail-navbar h1 {
        font-size: 40px;
    }

    .hero-content p {
        font-size: 14px;
    }

    .dropdown-content {
        left: -200%;
    }

    .dropdown-content ul li {
        font-size: 12px;
        width: 150px;
    }

    .dropdown-content ul span img {
        display: none;
    }

}

@media screen and (max-width: 768px) {
    .video-modal {
        width: fit-content;
        height: fit-content;
    }

    .video-header {
        font-size: 10px;
    }

    .video-body {
        height: fit-content;
    }

    .category-modal {
        display: flex !important;
    }

    .category-modal .modal-content {
        padding: 10px !important;
        background-color: #f6f6f6;
    }

    .category-modal .modal-header {
        margin-bottom: 0px;
    }

    .category-modal .row- {
        justify-content: center !important;
        align-items: center;
    }

    .category-modal .row- span {
        background-color: #E6E5F9;
        border-radius: 8px;
        border: 1px solid var(--primary-color);
        box-shadow: var(--shadow);
        margin: 0.2rem;
    }

    .side-bar {
        display: none;
    }

    .navbar {
        padding: 20px;
    }

    .my-plan {
        display: none;
    }

    .navbar .nav-item {
        display: none;
    }

    .nav-link {
        font-size: 12px !important;
    }

    .profile img {
        width: 30px !important;
        height: 30px !important;
    }

    .nav-acc .profile span {
        display: none;
    }

    .nav-profile-dropdown {
        top: 120%;
        left: -200px;
    }

    .dropdown-content {
        display: block !important;
        position: relative;
        top: 0%;
        left: 0%;
        background-color: transparent;
    }

    .dropdown-content ul .col {
        display: flex;
        flex-direction: row;
    }

    .dropdown-content ul .row- {
        display: flex;
        flex-direction: column;
    }

    .dropdown-content ul li {
        border-right: none;
    }

    .nav-mob-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #243459;
    }

    /* .icon-menu {
        display: inline-flex;
    } */

    /* .navbar .nav-item,
    .nav-acc {
        display: none;
    } */

    .Hero-section {
        height: 350px;
    }

    .hero-content h1,
    .jobDetail-navbar h1 {
        font-size: 34px;
    }

    .footer {
        padding: 20px;
    }

    .dropdown-menu-content img {
        display: none;
    }

    .search-button span {
        display: none;
    }

    .search-icon {
        display: block;
    }

}

@media screen and (max-width: 500px) {

    .credit-card-form label input {
        max-width: 85vw !important;
    }

    .card-element-wrapper {
        max-width: 85vw !important;
        padding: 5px !important;
    }

    .navbar {
        padding: 10px;
    }

    .dropdown-content ul li {
        text-align: left;
        font-size: 10px;
        width: 28vw;
        max-width: none;
        border: 0px;
        margin: 0rem;
        align-items: center;
        word-wrap: break-word;
    }

    .hero-content {
        padding: 10px;
    }

    .navbar img {
        width: 75px;
        height: 40px;
        object-fit: contain;
    }

    .hero-content p {
        font-size: 12px;
    }

    .hero-search form {
        width: 90%;
    }

    .hero-search form input {
        max-width: 80%;
        font-size: 12px;
    }

    .hero-search form button {
        max-width: 20%;
        font-size: 12px;
        padding: .9rem;
    }

    .hero-content h1,
    .jobDetail-navbar h1 {
        font-size: 24px;
    }


    .job-box {
        padding: 15px;
    }

    .job-box h3 {
        font-size: 16px;
    }

    .job-box p {
        font-size: 12px;
    }

    .job-box span {
        font-size: 10px;
    }

    .job-box .job-building-icon,
    .skeleton-icon {
        display: none;
    }
}